.verticalText {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  /*background-color: red;*/
  /*border: 1px solid;*/
}

.noMarginRow1 {
  margin: 0 !important;
}

div.noMarginRow2 {
  margin: 0 !important;
  /* width: 100% !important; */
}

.noMarginRow3 {
  margin: 0 !important;
}

.processDetailCardTitle {
  color: white;
}

.clusterDetailCardTitle {
  color: white;
}
